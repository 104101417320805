import "react-circular-progressbar/dist/styles.css";

import { Link } from "gatsby";
import React, { useState } from "react";
import Faq from "react-faq-component";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import $ from "jquery";

import { Layout, ContactFormDark } from "../components";

import locales from "../constants";

const data = {
  rows: [
    {
      title:
        "Czy muszę mieć konto na TikTok’u, aby reklamować się w tej aplikacji?",
      content:
        "Nie ma takiej konieczności. Uruchomimy kampanię reklamową dla Twojej firmy bez potrzeby zakładania konta w aplikacji.",
    },
    {
      title: "Do jakiej grupy docelowej mogę dotrzeć?",
      content:
        " Użytkownicy TikToka to bardzo obszerna grupa odbiorców, w której szczególne miejsce zajmują osoby w wieku 18-35 lat (ale!) starsza widownia również coraz mocniej się rozrasta. Możesz być pewny, że odpowiednie targetowanie zapewni dotarcie do Twojej docelowej grupy użytkowników.",
    },
    {
      title: "Ile kosztuje kampania na TikToku?",
      content:
        " Na koszt kampanii składa się budżet mediowy, który chcesz przeznaczyć na kampanię reklamową oraz koszt obsługi kampanii przez naszą agencję. TikTok oferuje 2 tryby targetowania użytkowników - SIMPLIFIED (uproszczony) i CUSTOM (rozbudowany). W trybie Simplified najniższy możliwy budżet dzienny zestawu reklam to 20 zł, a w trybie Custom to 80 zł.",
    },
    {
      title: "Gdzie wyświetlają się reklamy?",
      content:
        "Reklamy wyświetlają się jako pełnoekranowe filmiki w aplikacji TikTok.",
    },
    {
      title: "Jakie sposoby płatności są możliwe?",
      content:
        "Płatność można dokonać za pomocą karty płatniczej bądź poprzez PayPal. W przypadku karty, dostępna jest opcja płatności manualnej oraz płatności automatycznej.",
    },
  ],
};

const ChatBotPage = ({ location: { pathname } }) => {
  const [progress_visible_1, setProgressVisible1] = useState(false);

  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            speed: 2000,
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, options.speed / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "osobach, które obejrzały Twoje filmiki",
        "osobach, które odwiedziły Twoją stronę www",
        "osobach, które zostawiły lajka, komentarz i udostępniły film",
        "osobach, które podjęły działanie w aplikacji",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Kampanie Tiktok Ads, Reklama na TikToku",
        description:
          "Zwiększysz widoczność i sprzedaż swojej marki za pomocą Tiktok Ads. Zapewniamy Ci skuteczną kampanię reklamową TikTok Ads opartą na Twoich potrzebach. Z nami osiągniesz sukces w reklamach na platformie TikTok i zwiększysz zasięg swojej marki. Skontaktuj się i dowiedz się więcej o naszej usłudze TikTok Ads!",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          <span>TikTok Ads</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#social-media">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Social Media:</p>
            <ul>
              <li>
                <Link to="/audyt-kanalow-social-media/">
                  Audyt kanałów social media
                </Link>
              </li>
              <li>
                <Link to="/strategia-komunikacji-social-media/">
                  Strategia komunikacji <br /> social media
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-facebooku/">
                  Prowadzenie strony na Facebooku
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-konta-instagram/">
                  Prowadzenie konta Instagram
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-linkedin/">
                  Prowadzenie strony na LinkedIn
                </Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li className="current">
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              {/* <li>
                <Link to="/chatbot-w-komunikacji-marki/">
                  Chatbot w komunikacji marki
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Wykorzystaj moc TikToka
                </h2>
                <p className="text-col__desc">
                  Najszybciej rosnąca platforma społecznościowa, która aktualnie
                  zrzesza ponad miliard aktywnych użytkowników miesięcznie na
                  całym świecie! Niesamowite, prawda? Co sprawiło, że aplikacja
                  cały czas rośnie i zyskuje na popularności? To zasługa
                  autentycznych i naturalnych treści, które są aktualnie mocno
                  pożądane przez użytkowników przesyconych już wyidealizowanymi
                  treściami.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/img/tiktok.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/img/ads.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Wyprzedź swoją
                  <br />
                  konkurencję
                </h2>
                <p className="text-col__desc">
                  Wiele firm nie przekonało się jeszcze do reklam na Tiktoku
                  przyjmując, że ich grupy docelowej tak po prostu nie ma. Nic
                  bardziej mylnego! Jest to miejsce, w którym odbiorcy mogą
                  “być” trochę bliżej swoich ulubionych marek oraz łatwiej
                  odkrywać nowe! Liczba użytkowników w wieku +30 lat cały czas
                  rośnie, dlatego masz okazję wyprzedzić konkurencję i dotrzeć
                  do swoich potencjalnych klientów, tam, gdzie się Ciebie nie
                  spodziewają.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tiktok-graph offset-lg-4">
        <div className="row">
          <div className=" col-lg-8 col-xl-10">
            <h2 className="mb-40">Dlaczego Tiktok Ads?</h2>
            <div className="image-container tiktok-graph__image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/img/tiktok-graph.svg")}
                alt="Graf - kobieta z tabletem"
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/img/tiktok-graph-mobile.svg")}
                alt="Kobieta z tabletem"
              />
              <ul className="big-bullets list-mobile">
                <li>możliwość reklamowania bez konta organicznego</li>
                <li>błyskawiczne efekty</li>
                <li>autentyczny przekaz</li>
                <li>content wzbudzający zaufanie</li>
                <li>niska konkurencja</li>
                <li>precyzyjne targetowanie</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="single-offer-section-text-right progress">
        <div className="row">
          <div className=" col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="mb-40">Czy wiesz, że..?</h2>
            <div className="progress__wrapper">
              <div className="progress__item progress__item--center">
                <VisibilitySensor
                  offset={600}
                  onChange={(e) => (!!e ? setProgressVisible1(true) : null)}
                >
                  {({ isVisible }) => {
                    const percentage = isVisible || progress_visible_1 ? 62 : 0;
                    return (
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          pathTransitionDuration: 1.5,
                        })}
                      />
                    );
                  }}
                </VisibilitySensor>
                <div className="progress__item__text ">
                  <p>
                    użytkowników deklaruje, że kupiło produkt po zobaczeniu go
                    na TikTok
                  </p>
                </div>
              </div>
              <div className="progress__item progress__item--center">
                <VisibilitySensor>
                  {({ isVisible }) => {
                    const percentage =
                      isVisible || progress_visible_1 ? 100 : 0;
                    return (
                      <CircularProgressbar
                        value={percentage}
                        // text={`13\nmilionów`}
                        styles={buildStyles({
                          pathTransitionDuration: 1.5,
                        })}
                      />
                    );
                  }}
                </VisibilitySensor>
                <div className="progress__item__text">
                  <p>
                    liczba aktywnych użytkowników aplikacji przekroczyła w
                    Polsce liczbę 13 milionów
                  </p>
                </div>
              </div>
              <div className="progress__item progress__item--center">
                <VisibilitySensor>
                  {({ isVisible }) => {
                    const percentage = isVisible || progress_visible_1 ? 64 : 0;
                    return (
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          pathTransitionDuration: 1.5,
                        })}
                      />
                    );
                  }}
                </VisibilitySensor>
                <div className="progress__item__text">
                  <p>
                    użytkowników uważa, że TikTok to świetne miejsce do
                    odkrywania nowych produktów/marek.
                  </p>
                </div>
              </div>
            </div>
            <small style={{ marginTop: 50, display: "block", fontWeight: 300 }}>
              Źródło: TikTok For Business
            </small>
          </div>
        </div>
      </section>

      <section className="examples offset-lg-4">
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <h3>Dotrzyj do potencjalnych odbiorców </h3>
            <p>
              TikTok oferuje nam targetowanie reklam na podstawie lokalizacji,
              wieku, płci, zainteresowań i zachowań oraz urządzeń, z których
              korzysta odbiorca. Dzięki silnemu zaangażowaniu użytkowników,
              którzy spędzają w aplikacji blisko 4 razy więcej czasu niż na
              Instagramie, możemy być pewni, że dotrzemy z treściami do
              potencjalnych klientów zdecydowanie szybciej niż w innym mediach
              społecznościowych. Grupy niestandardowych odbiorców, które również
              są możliwe do utworzenia w menadżerze reklam, pozwolą na dotarcie
              do najbardziej cennych użytkowników, np. takich, którzy już
              odwiedzili stronę www, obserwują nasz profil na TikTok’u czy też
              kliknęły w przycisk CTA w materiale reklamowym.
            </p>
          </div>
        </div>
      </section>

      <section className="single-offer-section-text single-offer-section-text--kampanie-na-ig">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Będziemy targetować
                <br />
                Twoją reklamę Tiktok po:
              </span>{" "}
              <br />
              <span id="shuffle">osobach, które obejrzały Twoje filmiki</span>
            </h2>
          </div>
        </div>
      </section>

      <section className="examples offset-lg-4">
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <h2>Cele reklamowe, dostępne w TikTok Ads:</h2>
            <ul className="big-bullets">
              <li>zasięg</li>
              <li>ruch</li>
              <li>wyświetlenia wideo</li>
              <li>interakcje użytkowników</li>
              <li>promocja aplikacji</li>
              <li>pozyskiwanie kontaktów</li>
              <li>konwersje</li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <h2>Don’t Make Ads. Make TikToks.</h2>
            <p>
              Content reklamowy na TikTok’u powinien być jak najbardziej
              naturalny. To nie miejsce na idealne reklamy, do których
              przyzwyczaiły nas inne media społecznościowe. Im bardziej reklama
              wpasuje się w content pojawiający się w zakładce dla Ciebie, tym
              lepiej. Efekty reklam na Tiktoku są dosłownie błyskawiczne! W
              zależności od wybranego celu - zasięgi, wyświetlenia czy
              zaangażowanie rosną niesamowicie szybko. Jest to zdecydowany plus,
              gdzie np. w przypadku Facebook Ads na wysokie wyniki trzeba
              odpowiednio zaczekać.
            </p>
          </div>
        </div>
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <h3>Model aukcyjny (In-Feed Ads)</h3>
            <p>
              Opiera się na reklamach wideo wyświetlanych w sekcji "Dla Ciebie".
              Jest to jedno z tańszych rozwiązań na TikToku. Podobnie jak na
              Facebooku, czy w Google - ceny zależne są od konkurencji i
              składanych ofert.
            </p>
          </div>
        </div>
      </section>

      <section className="single-offer-section-single-video single-offer-section-single-video--kampanie-fb">
        <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
          <div className="phone-showcase">
            <div className="phone-showcase__item">
              <div className="phone-showcase__image">
                <img
                  src={require("../assets/img/tiktok-img-1.jpg")}
                  alt=""
                  style={{ width: 244 }}
                />
              </div>
              <div className="phone-showcase__text">
                <h2>Spark Ads</h2>
                <p>
                  Format reklamowy, który umożliwia wykorzystanie organicznych
                  postów opublikowanych w aplikacji TikTok.
                </p>
                <h2>Reklama wideo</h2>
                <p>
                  Oparta na materiale wideo wgranym bezpośrednio do menadżera
                  reklam podczas tworzenia kampanii reklamowej. Tego typu
                  reklama nie wymaga posiadania konta na TikToku.
                </p>
              </div>
            </div>
            <div className="phone-showcase__item">
              <div className="phone-showcase__image">
                <img
                  src={require("../assets/img/tiktok-img-2.jpg")}
                  alt=""
                  style={{ width: 244 }}
                />
              </div>
              <div className="phone-showcase__text">
                <h2 className="single-offer-section-single-video__title">
                  Reklama produktowa
                </h2>
                <p className="single-offer-section-single-video__desc">
                  Umożliwia prezentację produktów. Dzięki przyciskowi CTA
                  umożliwia łatwe przekierowanie użytkowników do Twojego sklepu
                  online lub dedykowanej Instant Page.
                </p>
              </div>
            </div>
            <div className="phone-showcase__item">
              <div className="phone-showcase__image">
                <img
                  src={require("../assets/img/tiktok-img-3.jpg")}
                  alt=""
                  style={{ width: 244 }}
                />
              </div>
              <div className="phone-showcase__text">
                <h2 className="single-offer-section-single-video__title">
                  Reklama z formularzem
                </h2>
                <p className="single-offer-section-single-video__desc">
                  Umożliwia pozyskiwanie cennych danych od zainteresowanych
                  ofertą użytkowników.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="examples offset-lg-4">
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <h3>Model rezerwacyjny</h3>
            <p>
              Wymaga znacznie większego budżetu mediowego oraz konkretnego
              rozplanowania w czasie (dział sprzedaży TikToka musi zarezerwować
              dla nas przestrzeń reklamową w dostępnym terminie). Formaty
              reklamowe dostępne w tym modelu:
            </p>
          </div>
        </div>
        <div className="row justify-content-between justify-content-xl-start">
          <div className="col-12 col-md-9 ">
            <ul className="big-bullets">
              <li>
                <strong>TopView</strong>
                <br />
                pierwsza reklama, która pojawia się w sekcji “Dla Ciebie” po
                włączeniu aplikacji.
              </li>
              <li>
                <strong>Brand Takeover</strong>
                <br />
                materiał reklamowy wyświetla się tylko raz dziennie zaraz po
                otwarciu aplikacji TikTok.
              </li>
              <li>
                <strong>Branded Hashtag Challenge</strong>
                <br />
                angażuje odbiorców do dołączenia do trendu stworzonego przez
                markę i wykonania własnej wersji filmiku.
              </li>
              <li>
                <strong>Branded Effects</strong>
                <br />
                reklama, która zachęca do korzystania ze stworzonych na potrzeby
                marki efektów specjalnych i naklejek.
              </li>
              <li>
                <strong>Brand Premium</strong>
                <br />
                format ten pojawia się w sekcji “Dla Ciebie” i może trwać od 5
                do 60 sekund
              </li>
              <li>
                <strong>OneDay Max</strong>
                <br />
                pierwszy materiał sponsorowany, który pojawia się po 5-7
                filmikach w feedzie użytkownika
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="faq-google single-offer-section-text-left--cat-4 single-offer-section-text-left">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 mt-100">
            <h2 className="text-col__title mb-40">FAQ TikTok Ads</h2>
            <Faq
              data={data}
              styles={{
                rowContentPaddingBottom: "60px",
                rowContentPaddingTop: "10px",
              }}
              config={{
                arrowIcon: (
                  <img
                    src={require("../assets/images/ico-faq_arrow.svg")}
                    alt=""
                  />
                ),
              }}
            />
          </div>
        </div>
      </section>

      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt="Ręka"
        />
      </section>
    </Layout>
  );
};
export default ChatBotPage;
